body {
  margin: 7.5vh 0px;
  background-color: #ecf0f1;
}
body > nav.navbar #veeva-popup-outer {
  display: none;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
}
body > nav.navbar #veeva-popup-outer #veeva-popup {
  bottom: 7.5vh;
  position: fixed;
  right: 5px;
  background: #0d213a;
  min-height: 20vh;
  min-width: 200px;
}
body > nav.navbar #veeva-popup-outer #veeva-popup h4 {
  font-size: 1.4em;
  color: #FFF;
  text-align: center;
  padding: 10px 0px;
}
body > nav.navbar #veeva-popup-outer #veeva-popup a {
  width: 95%;
  margin: 5px auto;
  display: block;
  border: 1px solid #FFF;
}
body > nav.navbar.main .navbar-brand {
  background-image: url('/img/logo.png');
  background-position: right center;
  background-repeat: no-repeat;
  width: 140px;
  background-size: 118px 27px;
}
body > nav.navbar.main .dropdown-menu {
  position: static;
  float: none;
  top: 5vh;
  background-color: #0d213a;
}
@media (min-width: 768px) {
  body > nav.navbar.main .dropdown-menu {
    position: absolute;
    float: left;
    background-color: #ffffff;
  }
}
body > nav.navbar.main .dropdown-menu .divider {
  margin: 0;
  background-color: #697887;
}
@media (min-width: 768px) {
  body > nav.navbar.main .dropdown-menu .divider {
    background-color: #e5e5e5;
  }
}
body > nav.navbar.main .dropdown-menu a {
  padding-top: 10px;
  padding-bottom: 10px;
}
body > nav.navbar.main .dropdown-menu a > i {
  margin-right: 10px;
}
body > nav.navbar.main ul.navbar-nav > li > a {
  color: #cccccc;
  padding-top: 1vh;
  padding-bottom: 1vh;
  line-height: 2.3vh;
}
@media (min-width: 768px) {
  body > nav.navbar.main ul.navbar-nav > li > a {
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    padding-right: 9px;
    padding-left: 9px;
  }
}
body > nav.navbar.main ul.navbar-nav > li > a > i {
  display: inline-block;
  background-color: #cccccc;
  border-radius: 50px;
  height: 2.3vh;
  width: 2.3vh;
  color: #0d213a;
  text-align: center;
  line-height: 2.6vh;
  font-size: 1.3vh;
}
body > nav.navbar.main ul.navbar-nav > li > a:hover,
body > nav.navbar.main ul.navbar-nav > li > a:focus,
body > nav.navbar.main ul.navbar-nav > li > a.current-page {
  color: #009CCA !important;
}
body > nav.navbar.main ul.navbar-nav > li > a:hover > i,
body > nav.navbar.main ul.navbar-nav > li > a:focus > i,
body > nav.navbar.main ul.navbar-nav > li > a.current-page > i {
  background-color: #009CCA;
}
body > nav.navbar.main ul.navbar-nav > li.dropdown.open > a > i {
  background-color: #009CCA;
}
body > nav.navbar.main ul.navbar-nav > li.divider {
  width: 100%;
  height: 1px;
  background-color: #535e6a;
}
@media (min-width: 768px) {
  body > nav.navbar.main ul.navbar-nav > li.divider {
    width: 1px;
    height: 7.5vh;
  }
}
body > nav.navbar.main .dropdown.country-picker img.svg {
  display: none;
}
body > nav.navbar.main .dropdown.country-picker .icon svg {
  height: 5.5vh;
  width: 4.1vw;
}
body > nav.navbar.main .dropdown.country-picker path.land {
  fill: #374A5E;
  stroke: #000;
}
body > nav.navbar.main .dropdown.country-picker .scotland.icon #GB-SC {
  fill: #009CCA;
}
body > nav.navbar.main .dropdown.country-picker .england.icon .land {
  fill: #009CCA;
}
body > nav.navbar.main .dropdown.country-picker .england.icon #GB-SC,
body > nav.navbar.main .dropdown.country-picker .england.icon #GB-WA,
body > nav.navbar.main .dropdown.country-picker .england.icon #GB-NI,
body > nav.navbar.main .dropdown.country-picker .england.icon #IE {
  fill: #374A5E;
}
body > nav.navbar.main .dropdown.country-picker .wales.icon #GB-WA {
  fill: #009CCA;
}
body > nav.navbar.main .dropdown.country-picker .ireland.icon #GB-NI {
  fill: #009CCA;
}
body > nav.navbar.main .dropdown.country-picker > a {
  text-align: right;
  padding: 0.7vh;
  width: 6.5vw;
  background-position: 40% center;
  background-repeat: no-repeat;
  color: #cccccc;
}
@media (max-width: 767px) {
  body > nav.navbar.main .dropdown.country-picker > a {
    text-align: left !important;
    width: 100% !important;
  }
  body > nav.navbar.main .dropdown.country-picker > a .country-text {
    position: absolute;
    top: 30px;
    left: 50px;
  }
}
body > nav.navbar.main .dropdown.country-picker > .dropdown-menu {
  background-color: #0d213a;
  padding: 0px;
  top: 100% !important;
  right: -1px !important;
}
body > nav.navbar.main .dropdown.country-picker > .dropdown-menu > li > a {
  color: #cccccc;
  background-position: 80% center;
  background-repeat: no-repeat;
  border-top: 1px solid #081525;
  padding-top: 15px;
  padding-bottom: 15px;
}
body > nav.navbar.main .dropdown.country-picker > .dropdown-menu > li > a span {
  padding-top: 20px;
  float: left;
  width: 80px;
}
body > nav.navbar.main .dropdown.country-picker > .dropdown-menu > li > a:hover {
  color: #ffffff;
  background-color: #122d4f;
}
body > nav.navbar .dropdown-block-lhs {
  margin-top: 25px;
  display: inline-block;
}
body > nav.navbar #custom-astellas {
  background-image: url(/img/company_logos/astellas.svg);
  background-position: right center;
  background-size: 150px 40px;
  background-repeat: no-repeat;
  width: 150px;
  float: left;
  height: 7.5vh;
}
body > nav.navbar #custom-abbott {
  background-image: url(/img/company_logos/abbott_laboratories_white_logo.svg);
  background-position: right center;
  background-size: 130px 40px;
  background-repeat: no-repeat;
  width: 140px;
  float: left;
  height: 7.5vh;
}
body > nav.navbar #custom-teva {
  background-image: url(/img/company_logos/teva.png);
  background-position: right center;
  background-size: 80%;
  background-repeat: no-repeat;
  width: 200px;
  float: left;
  height: 75px;
}
body > nav.navbar #custom-sanofi {
  background-image: url(/img/company_logos/sanofi-wide.png);
  background-position: 10% 80%;
  background-size: 201px;
  background-repeat: no-repeat;
  width: 300px;
  float: left;
  height: 7.5vh;
}
@media (min-width: 767px) and (max-width: 899px) {
  body > nav.navbar #custom-astellas,
  body > nav.navbar #custom-teva,
  body > nav.navbar #custom-sanofi {
    display: none;
  }
}
body .modal .modal-dialog {
  margin-bottom: 150px;
}
body .modal .modal-dialog > .modal-content .modal-header {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #009CCA;
  color: #ffffff;
  border-bottom: 0px;
}
body #nav-filtermenu #nav-filtermenu-icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #009CCA;
}
body #nav-filtermenu #filter-presets-wrapper {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
}
body #nav-filtermenu #filter-presets-wrapper #filter-presets-menu {
  margin-bottom: 15px;
  min-width: 290px;
  padding-top: 20px;
  padding-bottom: 5px;
}
body #nav-filtermenu #filter-presets-wrapper #filter-presets-menu li {
  padding-top: 7px;
  padding-bottom: 7px;
}
body #nav-filtermenu #filter-presets-wrapper #filter-presets-menu li.divider {
  padding-top: 0px;
  padding-bottom: 0px;
}
body #nav-filtermenu #filter-presets-wrapper #filter-presets-menu .filter-trash {
  padding-top: 2px;
  color: #777777 !important;
}
body #nav-filtermenu #filter-presets-wrapper #filter-presets-menu .filter-trash:hover {
  color: #000 !important;
}
body #nav-filtermenu #filter-presets-wrapper #filter-presets-menu #new-preset {
  width: 250px;
  margin-left: 20px;
  padding: 4px;
}
body #nav-filtermenu #nav-filtermenu-items {
  margin-left: 150px;
}
body #nav-filtermenu #nav-filtermenu-items a,
body #nav-filtermenu #filter-presets-link {
  color: #777777;
}
body #nav-filtermenu #nav-filtermenu-items a:hover,
body #nav-filtermenu #filter-presets-link:hover {
  color: #000000;
}
body .analysis-table,
body .hw-table {
  width: 100%;
  border-bottom: 0px !important;
}
body .analysis-table.monthly thead tr:nth-child(2) th,
body .hw-table.monthly thead tr:nth-child(2) th {
  min-width: 40px;
}
body .analysis-table th,
body .hw-table th {
  background: #009CCA;
  color: #FFFFFF;
  font-size: 12px;
  padding: 20px 10px 20px 10px !important;
  font-weight: normal !important;
  border-left: 1px solid #0384aa;
  border-bottom: 0px !important;
}
body .analysis-table th:first-child,
body .hw-table th:first-child {
  border-left: 0px;
}
body .analysis-table td,
body .hw-table td {
  color: #777777;
  font-size: 12px;
  padding: 12px 10px 12px 10px;
}
body .analysis-table td .drilldown,
body .hw-table td .drilldown {
  color: #009CCA;
  cursor: pointer;
}
body .inline-block {
  display: inline-block;
}
body .alert-danger {
  color: #fff;
}
body #downloads-counter {
  background: #ccc;
  color: #555;
  padding: 3px 7px 5px 7px;
  margin-top: -2px;
  margin-left: 5px;
}
body .m-t-xlg {
  margin-top: 3vh;
}
body .m-b-xlg {
  margin-bottom: 3vh;
}
body .m-l-xlg {
  margin-left: 3vh;
}
body .m-r-xlg {
  margin-right: 3vh;
}
body .m-t-lg {
  margin-top: 2.5vh;
}
body .m-b-lg {
  margin-bottom: 2.5vh;
}
body .m-l-lg {
  margin-left: 2.5vh;
}
body .m-r-lg {
  margin-right: 2.5vh;
}
body .m-t-md {
  margin-top: 2vh;
}
body .m-b-md {
  margin-bottom: 2vh;
}
body .m-l-md {
  margin-left: 2vh;
}
body .m-r-md {
  margin-right: 2vh;
}
body .p-t-xlg {
  padding-top: 3vh;
}
body .p-b-xlg {
  padding-bottom: 3vh;
}
body .p-l-xlg {
  padding-left: 3vh;
}
body .p-r-xlg {
  padding-right: 3vh;
}
body .p-t-lg {
  padding-top: 2.5vh;
}
body .p-b-lg {
  padding-bottom: 2.5vh;
}
body .p-l-lg {
  padding-left: 2.5vh;
}
body .p-r-lg {
  padding-right: 2.5vh;
}
body .p-t-md {
  padding-top: 2vh;
}
body .p-b-md {
  padding-bottom: 2vh;
}
body .p-l-md {
  padding-left: 2vh;
}
body .p-r-md {
  padding-right: 2vh;
}
body .p-t-sm {
  padding-top: 1.5vh;
}
body .p-b-sm {
  padding-bottom: 1.5vh;
}
body .p-l-sm {
  padding-left: 1.5vh;
}
body .p-r-sm {
  padding-right: 1.5vh;
}
body h1 .current-codes {
  display: block;
  padding-top: 7px;
  color: #666;
  font-size: 1.6vh;
}
body .loading-page {
  z-index: 1090;
  top: 0px;
  left: 0px;
}
body .loading-page .content {
  margin: auto;
  text-align: center;
  color: #FFF;
  top: calc(50% - 30px);
  left: 0px;
  right: 0px;
  position: fixed;
  font-size: 16px;
}
body .loading-page .loading-header {
  margin: auto;
  text-align: center;
  color: #FFF;
  top: calc(50% - 100px);
  left: 0px;
  right: 0px;
  position: fixed;
  font-size: 32px;
}
body .loading-page .loading-bg {
  margin: auto;
  text-align: center;
  color: #FFF;
  top: 50%;
  left: 0px;
  right: 0px;
  position: fixed;
  font-size: 64px;
}
body .loading-page .loading-bg .fa-cog:not(first-child) {
  margin-left: -10px;
}
body #custom-footer {
  position: fixed;
  bottom: 5px;
  left: 0px;
  right: 0px;
  text-align: center;
  z-index: 9000;
}
