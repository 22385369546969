@import "includes/variables.less";
@import "includes/bootstrap/mixins.less";
@import "includes/mixins/mixins.less";
body{
	margin: @navbar-height 0px;
        background-color: @gray-lighter;
	
	> nav.navbar {
        #veeva-popup-outer{
            display: none;
            position: fixed;
            top:0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            z-index: 99999;
            background-color: rgba(0, 0, 0, 0.5);
            #veeva-popup{
                bottom: 7.5vh;
                position: fixed;
                right: 5px;
                background: #0d213a;
                min-height:20vh;
                min-width: 200px;
                h4{
                    font-size: 1.4em;
                    color: #FFF;
                    text-align: center;
                    padding: 10px 0px;
                }
                a{
                    width: 95%;
                    margin: 5px auto;
                    display:block;
                    border:1px solid #FFF;
                }
            }
        }
		&.main {
			.navbar-brand {
				background-image: url('/img/logo.png');
				background-position: right center;
				background-repeat: no-repeat;
				width: @brand-logo-width;
                                background-size: 118px 27px
			}

			.dropdown-menu {
				position: static;
				float: none;
				top: 5vh;
				background-color: @navbar-default-bg;

				@media (min-width: @grid-float-breakpoint) {
					position: absolute;
					float: left;
					background-color: @dropdown-bg;
				}

				.divider {
					margin: 0;
					background-color: @navbar-default-link-color;

					@media (min-width: @grid-float-breakpoint) {
						background-color: @dropdown-divider-bg;
					}
				}

				a {
					padding-top: 10px;
					padding-bottom: 10px;

					> i {
						margin-right: 10px;
					}

				}
			}

			ul.navbar-nav > li {

				> a {
                    color: @gray-white;
					padding-top: 1vh;
					padding-bottom: 1vh;
                    line-height:2.3vh;

					@media (min-width: @grid-float-breakpoint) {
						padding-top: 2.5vh;
						padding-bottom: 2.5vh;
						padding-right: 9px;
						padding-left: 9px;
					}

					> i {
						display: inline-block;
						background-color: @gray-white;
						border-radius: 50px;
						height: 2.3vh;
						width: 2.3vh;
                                                color: @navbar-default-bg;
						text-align: center;
						line-height: @font-size-small * 2;
						font-size: @font-size-small;
					}

					&:hover, &:focus, &.current-page {
                                            color: @brand-secondary !important;
						> i {
                                                        background-color: @brand-secondary;
						}
					}
				}

				&.dropdown.open {
					> a > i {
						background-color: @color-primary-accent;
					}
				}

				&.divider {
					width: 100%;
					height: 1px;
					background-color: darken(@navbar-default-link-color, 10%);

					@media (min-width: @grid-float-breakpoint) {
						width: 1px;
						height: 7.5vh;
					}
				}

			}

                        .dropdown.country-picker {
                            img.svg {
                                display: none;
                            }
                                .icon svg {
                                        height: 5.5vh;	
                                        width: 4.1vw;
                                }
                                path.land {
                                        fill: #374A5E;
                                        stroke: #000;
                                }
                                .scotland.icon {
                                        #GB-SC {
                                            fill: @color-primary-accent;
                                        }
                                }
                                .england.icon {
                                        .land {
                                            fill: @color-primary-accent;
                                        }
                                        #GB-SC, #GB-WA, #GB-NI, #IE {
                                            fill: #374A5E;
                                        }
                                }
                                .wales.icon {
                                        #GB-WA {
                                                fill: @color-primary-accent;
                                        }
                                }
                                .ireland.icon {
                                        #GB-NI {
                                                fill: @color-primary-accent;
                                        }
                                }
                                > a {
                                        text-align: right;
                                        padding: 0.7vh;
                                        width: 6.5vw;
                                        background-position: 40% center;
                                        background-repeat: no-repeat;
                                        color: @gray-white;
                                        
                                        @media (max-width: 767px) {
                                            text-align: left !important;
                                            width: 100% !important;
                                            .country-text { 
                                                position: absolute;
                                                top: 30px;
                                                left: 50px;
                                            }
                                        }
                                }

                                > .dropdown-menu {
                                        background-color: @color-primary-primary;
                                        padding: 0px;
                                        top: 100% !important;
                                        right: -1px !important;

                                        > li > a {
                                                color: @gray-white;
                                                background-position: 80% center;
                                                background-repeat: no-repeat;
                                                border-top: 1px solid darken(@color-primary-primary, 5%);

                                                        padding-top: 15px;
                                                        padding-bottom: 15px;

                                                        span {
                                                            padding-top: 20px;
                                                            float: left;
                                                            width: 80px;
                                                        }

                                                &:hover {
                                                        color: rgb(255, 255, 255);
                                                        background-color: lighten(@color-primary-primary, 5%);
                                                }
                                                                                                }
                                }
                        }
		}
                
                .dropdown-block-lhs {
                    margin-top: 25px;
                    display: inline-block;
                }
                
                #custom-astellas {
                    background-image:url(/img/company_logos/astellas.svg);
                    background-position:right center;
                    background-size: 150px 40px;
                    background-repeat:no-repeat;
                    width:150px;
                    float:left;
                    height:7.5vh;
                }
                #custom-abbott {
                    background-image:url(/img/company_logos/abbott_laboratories_white_logo.svg);
                    background-position:right center;
                    background-size: 130px 40px;
                    background-repeat:no-repeat;
                    width:140px;
                    float:left;
                    height:7.5vh;
                }
                #custom-teva {
                    background-image:url(/img/company_logos/teva.png);
                    background-position:right center;
                    background-size: 80%;
                    background-repeat:no-repeat;
                    width:200px;
                    float:left;
                    height:75px;
                }

                #custom-sanofi {
                    background-image:url(/img/company_logos/sanofi-wide.png);
                    background-position: 10% 80%;
                    background-size: 201px;
                    background-repeat: no-repeat;
                    width: 300px;
                    float: left;
                    height: 7.5vh;
                }

                @media (min-width: 767px) and (max-width: 899px) {
                    #custom-astellas,#custom-teva,#custom-sanofi {
                        display:none;
                    }
                }
                
	}

	.modal {
            .modal-dialog {
                margin-bottom: 150px;
                > .modal-content .modal-header {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    background-color: @color-primary-accent;
                    color: rgb(255, 255, 255);
                    border-bottom: 0px;
                }
            }
	}

        #nav-filtermenu {
            
            #nav-filtermenu-icon {
                position:absolute;
                left:20px;
                top: 50%;
                transform: translateY(-50%);
                color:@brand-secondary;
            }
            
            #filter-presets-wrapper {
                position: absolute;
                left: 50px;
                top: 50%;
                transform: translateY(-50%);
                
                #filter-presets-menu {
                    margin-bottom: 15px;
                    min-width: 290px;
                    padding-top: 20px;
                    padding-bottom: 5px;
                    
                    li {
                        padding-top:7px;
                        padding-bottom:7px;
                        
                        &.divider {
                            padding-top:0px;
                            padding-bottom:0px;
                        }
                    }
                    
                    .filter-trash {
                        padding-top: 2px;
                        color: @gray-medium !important;
                        &:hover {
                            color: #000 !important;
                        }
                    }
                    
                    #new-preset {
                        width: 250px;
                        margin-left: 20px;
                        padding: 4px;
                    }
                    
                }
            }

            #nav-filtermenu-items {
                margin-left:150px;
            }
            
            #nav-filtermenu-items a,
	    #filter-presets-link {
                color: @gray-medium;
                &:hover{
                    color: #000000;
                }
            }
        
        }
        
        .analysis-table, .hw-table {
            width:100%;
            border-bottom:0px !important;
            &.monthly{
                thead{
                    tr{
                        &:nth-child(2){
                            th{
                                min-width:40px;
                            }
                        }
                    }
                }
            }
            th {
                background: @brand-secondary;
                color: #FFFFFF;
                //font-size: @font-size-xsmall;
                font-size:12px;
                padding: 20px 10px 20px 10px !important;
                font-weight:normal !important;
                border-left:1px solid @brand-tertiary;
                border-bottom:0px !important;
            }
            
            th:first-child {
                border-left:0px;
            }
            
            td {
                color: @gray-medium;
                //font-size: @font-size-small;
                //font-size: 1.3em;
                font-size:12px;
                padding:12px 10px 12px 10px;
                .drilldown {
                    color: @brand-secondary;
                    cursor:pointer;
                }
            }
        }
        
	.inline-block {
		display: inline-block;
	}
        
        .alert-danger {
            color: #fff;
        }
        
        #downloads-counter {
            background: #ccc;
            color: #555;
            padding: 3px 7px 5px 7px;
            margin-top: -2px;
            margin-left: 5px;
        }
        
        .m-t-xlg {
            margin-top: @m-xlg;
        }
        .m-b-xlg {
            margin-bottom: @m-xlg;
        }
        .m-l-xlg {
            margin-left: @m-xlg;
        }
        .m-r-xlg {
            margin-right: @m-xlg;
        }
        
        .m-t-lg {
            margin-top: @m-lg;
        }
        .m-b-lg {
            margin-bottom: @m-lg;
        }
        .m-l-lg {
            margin-left: @m-lg;
        }
        .m-r-lg {
            margin-right: @m-lg;
        }

        .m-t-md {
            margin-top: @m-md;
        }
        .m-b-md {
            margin-bottom: @m-md;
        }
        .m-l-md {
            margin-left: @m-md;
        }
        .m-r-md {
            margin-right: @m-md;
        }
        
        .p-t-xlg {
            padding-top: @p-xlg;
        }
        .p-b-xlg {
            padding-bottom: @p-xlg;
        }
        .p-l-xlg {
            padding-left: @p-xlg;
        }
        .p-r-xlg {
            padding-right: @p-xlg;
        }
        
        .p-t-lg {
            padding-top: @p-lg;
        }
        .p-b-lg {
            padding-bottom: @p-lg;
        }
        .p-l-lg {
            padding-left: @p-lg;
        }
        .p-r-lg {
            padding-right: @p-lg;
        }

        .p-t-md {
            padding-top: @p-md;
        }
        .p-b-md {
            padding-bottom: @p-md;
        }
        .p-l-md {
            padding-left: @p-md;
        }
        .p-r-md {
            padding-right: @p-md;
        }

        .p-t-sm {
            padding-top: @p-sm;
        }
        .p-b-sm {
            padding-bottom: @p-sm;
        }
        .p-l-sm {
            padding-left: @p-sm;
        }
        .p-r-sm {
            padding-right: @p-sm;
        }
        
        h1 .current-codes {
            display:block;
            padding-top:7px;
            color:#666;
            font-size:1.6vh;
        }
        
        .loading-page{
            z-index: 1090;
            top:0px;
            left:0px;
            .content{
                margin: auto;
                text-align: center;
                color: #FFF;
                top: calc(50% - 30px);
                left: 0px;
                right: 0px;
                position: fixed;
                font-size: 16px;
            }
            .loading-header{
                margin: auto;
                text-align: center;
                color: #FFF;
                top: calc(50% - 100px);
                left: 0px;
                right: 0px;
                position: fixed;
                font-size: 32px;
            }
            .loading-bg{                
                margin: auto;
                text-align: center;
                color: #FFF;
                top: 50%;
                left: 0px;
                right: 0px;
                position: fixed;
                font-size: 64px;
                .fa-cog{
                    &:not(first-child){
                        margin-left:-10px;
                    }
                }
            }
        }
        
        #custom-footer {
            position:fixed;
            bottom:5px;
            left:0px;
            right:0px;
            text-align:center;
            z-index:9000;
        }

}
